




























import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import Service from '@/calendesk/models/DTO/Response/Service'
export default mixins(DraftElement).extend({
  name: 'ServiceCard',
  props: {
    service: {
      type: Service
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
})
