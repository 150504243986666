import mixins from 'vue-typed-mixins'
import Section from '@/calendesk/sections/section/mixins/Section'
import { mapActions, mapGetters } from 'vuex'
import SectionConfigurationService from '@/calendesk/models/SectionConfigurationService'
import Service from '@/calendesk/models/DTO/Response/Service'
import BookingDialogModel from '@/calendesk/models/BookingDialogModel'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'

export default mixins(Section).extend({
  data () {
    return {
      presentWithCategories: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      categoryAndServices: 'service/getCategoryAndServices',
      services: 'service/getServices'
    }),
    list (): Service[] {
      if (this.services) {
        const selectedServiceIds = this.data.configuration.wb_service_list__services__.map((item: SectionConfigurationService) => item.id)
        return this.services.filter((service: Service) => selectedServiceIds.includes(service.id))
      }

      return []
    }
  },
  created () {
    if (!this.services) {
      this.loading = true
      this.fetchCategoryAndServices().finally(() => { this.loading = false })
    }
  },
  methods: {
    ...mapActions({
      fetchCategoryAndServices: 'service/fetchCategoryAndServices'
    }),
    openBookingDialog (service: Service, serviceType: ServiceType | null) {
      const bookingDialogModel = new BookingDialogModel(
        service,
        null,
        serviceType,
        null,
        this.$moment().toDate().toDateString(),
        null,
        null)

      this.openDialog(new Dialog(
        true,
        DialogTypes.CREATE_BOOKING,
        null,
        DialogSize.LARGE,
        false,
        'center',
        {
          dialogData: bookingDialogModel,
          configurationData: this.data
        })
      )
    }
  }
})
